// Select component (vendor)
.Select {
  // sass-lint:disable-line class-name-format
  font-size: 0.85rem;

  .Select-option {
    // sass-lint:disable-line class-name-format
    transition: 0.3s color, 0.3s opacity, 0.3s background-color;
  }

  .Select-control {
    // sass-lint:disable-line class-name-format
    border-radius: 2px;
    cursor: pointer;
  }

  .Select-menu-outer {
    z-index: 10;
  }
}

@media (max-width: 479px) {
  .Select {
    font-size: 0.78rem;
  }

  .Select-input {
    height: 30px;
  }

  .Select-control {
    height: 32px;
  }

  .Select-placeholder,
  .Select--single > .Select-control {
    .Select-value {
      line-height: 30px;
    }
  }
}
