:root {
  --white-rgb: 255, 255, 255;
  --black-rgb: 0, 0, 0;
  --free-day-text: rgb(48, 230, 3);
  --active-checkbox-color: rgb(123, 207, 70);
  --checkbox-color: rgba(123, 207, 70, 0.2);

  /* Button Colors */
  /* DEFAULT: Orange/Red Button */
  --orange-red-claim-button-background: rgb(255, 88, 59);
  --orange-red-claim-button-background-gradient: linear-gradient(
    180deg,
    rgba(255, 88, 59, 1) 0%,
    rgba(250, 75, 50, 1) 28%,
    rgba(248, 56, 53, 1) 61%,
    rgba(245, 56, 36, 1) 100%
  );

  /* Gold Button */
  --gold-claim-button-background: rgb(210, 178, 74);

  /* Green Button */
  --green-claim-button-background: rgb(35, 172, 104);
  --green-claim-button-background-gradient: linear-gradient(180deg, rgba(35, 172, 104) 0%, rgb(26, 152, 88) 100%);

  /* Red Button */
  --red-claim-button-background: rgb(209, 20, 41);

  /* Button Borders */
  /* DEFAULT: Light Red Border */
  --red-claim-button-border: 1px solid rgba(255, 154, 154, 0.2);

  /* Light Gold Border */
  --gold-claim-button-border: 1px solid rgba(255, 220, 154, 0.2);

  /* Black Border */
  --black-claim-button-border: 1px solid rgba(var(--black-rgb), 1);
  /*  */
}
