.clear {
  clear: both;
}

.clear-left {
  clear: left !important;
}

.no-pad {
  padding-left: 0;
  padding-right: 0;
}

.inline-block {
  display: inline-block;
}

.col-dinline {
  display: inline-block;
  float: none;
  vertical-align: middle;
}

.va-m {
  vertical-align: middle;
}

.va-t {
  vertical-align: top;
}

.clickable:not(disabled) {
  cursor: pointer;
}

.btn {
  transition: 0.3s background-color;
}

[disabled] {
  pointer-events: none;
}

.unselectable {
  user-select: none;
}

.icon-rotate-transition {
  transition: 0.5s transform;
}

.v-offset-5 {
  margin-top: 5px;
}

.v-offset-10 {
  margin-top: 10px;
}

.v-offset-15 {
  margin-top: 15px;
}

.v-offset-20 {
  margin-top: 20px;
}

.v-offset-25 {
  margin-top: 25px;
}

.v-offset-30 {
  margin-top: 30px;
}

.v-offset-35 {
  margin-top: 35px;
}

.v-offset-40 {
  margin-top: 40px;
}

a:focus {
  text-decoration: none;
}

// Only verified css classes
.hidden-xxxs {
  @media (max-width: 479px) {
    display: none;
  }
}
