.slick-next,
.slick-prev {
  position: absolute;
  background: center no-repeat;

  &::before {
    width: 0;
    height: 0;
    visibility: hidden;
  }

  &:focus {
    outline: none;
  }

  &.slick-disabled {
    opacity: 0.5;

    &:hover {
      opacity: 0.5;
    }
  }
}

.slick-next {
  background-image: url('images/slide-next.png');

  &:hover {
    background-image: url('images/slide-next_hover.png');
  }
}

.slick-prev {
  background-image: url('images/slide-prev.png');

  &:hover {
    background-image: url('images/slide-prev_hover.png');
  }
}

.slick-initialized .slick-slide.slick-active {
  z-index: 1;
}
